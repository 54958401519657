<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div
				class="width-320 bg-white ma-auto radius-20"
			>
				<div class="pa-20 justify-space-between under-line">
					<h3>{{  $language.payment.method }}</h3>
					<v-icon  @click="$emit('cancel')">mdi-close-circle</v-icon>
				</div>
				<div>
					<ul>
						<li
							v-for="(currency, index) in currencies"
							:key="'currency_' + index"
							@click="$emit('click', currency)"
							class="pa-20 justify-space-between under-line-dashed cursor-pointer"
						>
							<span>{{ currency.label }}</span>

							<span v-if="currency.dicntrate" class="Blue01">{{ currency.sl_coin_token_quantity }} <span style="color: #DC505C">({{ currency.dicntrate }}% DC)</span></span>
							<span v-else class="Blue01">{{ currency.dc_before_sl_coin_token_quantity }}</span>


						</li>
						<li
							v-if="is_krw"
							@click="$emit('credit')"
							class="pa-20 justify-space-between cursor-pointer"
						>

							<span>{{  $language.common.credit_card }}</span>
							<span v-if="legal.payment_mthd_sl_price_dicntrate" class="Blue01">{{ legal.payment_mthd_sl_price }} <span style="color: #DC505C">({{ legal.payment_mthd_sl_price_dicntrate }}% DC)</span></span>
							<span v-else class="Blue01">₩ {{ krw_price}}</span>

						</li>
					</ul>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	name: 'mafia03911'
	,
	components: {PopupLayer},
	props: ['currencies', 'legal']
	, data: function(){
		return {

		}
	}
	, computed: {
		is_krw: function(){
			let t = false

			if(this.legal.sl_leglter_div_code == 'BC00800001'){
				t = true
			}

			return t
		}
		, krw_price: function(){
			let t = this.legal.payment_mthd_sl_price
			let d = true
			if(d){
				t *= 1.1
			}
			return t
		}
	}
	, methods: {

	}
	, created() {
	}
}
</script>

<style>
.layer_title h3 {
	display: flex !important; justify-content: space-between;
}
.create_wallet li button {
	display: flex !important; justify-content: space-between;
}

.Blue01 {
	color: #3E47B7;
	font-weight: 600;
}
</style>